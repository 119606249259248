import React, { useEffect } from "react";
// import * as PIXI from "pixi.js";
import Parallax from "parallax-js";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";

const COD = ({ pageContext: { slug } }) => {
  useEffect(() => {
    var scene = document.getElementById("scene");
    // eslint-disable-next-line
    var parallaxInstance = new Parallax(scene);
  });
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #A08C55, #AE985B, #BBA360, #AE985B, #A08C55 )",
        icons: "#c5af71",
        navClass: "cod",
      }}
      seo={{
        title: "Call of Duty: Advanced Warfare",
        headerTitle: "cod",
        href: slug,
        lang: "pl",
        ogImage: require("../../assets/img/portfolio/cod_header.png"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/call-of-duty-advanced-warfare/",
      }}
    >
      <PortfolioHeader name="cod" height="145" />
      <section className="container-fluid cod_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Call of Duty: Advanced Warfare</h1>
              <ul>
                <li>Strona internetowa</li>
              </ul>
            </div>
          </div>

          <div className="col-md-6">
            <div className="inner">
              <p>
                Call of Duty to jedna z najbardziej znanych gier komputerowych
                na świecie. Kolejne części opublikowanej serii ActiVision
                zostały sprzedane w setkach milionów egzemplarzy i osiągnęły
                spektakularny sukces komercyjny, nadając grze status jednej z
                najbardziej rozpoznawalnych marek w świecie gier. Naszym
                zadaniem było stworzenie strony promującej grę Call of Duty
                Advanced Warfare na polskim rynku. Oprócz pokazania, jak wygląda
                rozgrywka, głównym celem serwisu było przekierowanie
                użytkowników do sklepów internetowych dystrybuujących grę.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="cod_section_3">
        <div className="scroll_wrapper laptop">
          <span />
          <div className="img_wrapper">
            <img
              src={require("../../assets/img/portfolio/cod_entire_page.jpg")}
              alt=""
            />
          </div>
        </div>
        <img src={require("../../assets/img/portfolio/cod_bg.png")} alt="" />
        <div id="scene" className="debris__container">
          <img
            data-invert-x
            data-invert-y
            data-depth="0.1"
            src={require("../../assets/img/portfolio/cod_debris.png")}
            alt=""
            className="debris"
          />
        </div>
      </section>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default COD;
